<template>
  <div class="admin-add" v-if="!isProcessing">
    <h2 class="admin-add__title">
      管理者として追加したいユーザーのメールアドレス（アプリ登録で利用のもの）を入力してください。
    </h2>
    <admin-search class="admin-add__search" @set-searched="wasSearched = true" @set-user="setUser" />
    <template v-if="uid && user">
      <user-detail class="admin-add__detail" :user="user" :email="email" />
      <v-btn class="admin-add__btn" :disabled="isAdmin" depressed @click="doAdd()">管理者権限を付与する</v-btn>
    </template>
    <p class="admin-add__warning" v-if="wasSearched && !uid && !user">ユーザーが存在しません。</p>
    <p class="admin-add__warning" v-if="isAdmin">すでに管理者として登録されています。</p>
  </div>
</template>

<script>
import AdminSearch from '@/components/common/user/search'
import UserDetail from '@/components/common/user/detail'
export default {
  name: 'admin_add',
  components: { AdminSearch, UserDetail },
  data () {
    return {
      // 検索した結果のユーザーID
      uid: null,
      // 検索した結果のユーザー情報
      user: null,
      // 検索したユーザーのメールアドレス
      email: null,
      // 検索したかどうか
      wasSearched: false
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Boolean} 管理者かどうか
     */
    isAdmin () {
      return this.user && this.user.authority === 'admin'
    }
  },
  methods: {
    /**
     * 検索結果の格納
     * @param {String} uid ユーザー ID
     * @param {Object} user ユーザー情報
     * @param {String} email ユーザーのメールアドレス
     */
    setUser (uid, user, email) {
      this.uid = uid
      this.user = user
      this.email = email
    },
    /**
     * ユーザー権限の追加
     */
    async doAdd () {
      this.$store.commit('setSubmitting', true)

      // 権限とカスタムクレイムの追加
      await Promise.all([
        this.$store.dispatch('users/updateUser', {
          uid: this.uid,
          params: {
            authority: 'admin',
            updatedAt: new Date()
          }
        }),
        this.$store.dispatch('functions/setCustomClaims', {
          uid: this.uid,
          params: {
            admin: true
          }
        })
      ])

      // ホームへ遷移
      this.$store.commit('setTelop', { show: true, msg: this.user.name + 'さんを管理者にしました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.admin-add {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
  &__title {
    margin: 0;
    font-size: 1.2rem;
    font-weight: normal;
  }
  &__search {
    margin-top: 20px;
  }
  &__detail {
    margin-top: 40px;
  }
  &__btn {
    display: block;
    margin: 30px auto 0 auto;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 10px 15px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $orange_lighten_color !important;
    }
  }
  &__warning {
    margin-top: 20px;
    font-size: 1.2rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
