<template>
  <div class="user-search">
    <input class="user-search__input" placeholder="メールアドレス" v-model="email" />
    <v-btn class="user-search__btn" :disabled="!isCorrectFormatted" icon @click="search()">
      <v-icon class="user-search__btn__icon">search</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // メールアドレス
      email: null
    }
  },
  computed: {
    /**
     * @return {Boolean} メールアドレスが正しいフォーマットかどうか
     */
    isCorrectFormatted () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return this.email && pattern.test(this.email)
    }
  },
  methods: {
    /**
     * メールアドレスからユーザー情報を検索する
     */
    async search () {
      this.$store.commit('setSubmitting', true)
      this.$emit('set-searched')
      // ユーザー情報の取得
      const results = await this.$store.dispatch('functions/getUserByEmail', this.email)
      if (!results.uid || !results.user) {
        this.$emit('set-user', null, null)
        this.$store.commit('setSubmitting', false)
        return
      }
      // 親コンポーネントにユーザー情報を渡す
      this.$emit('set-user', results.uid, results.user, this.email)
      this.$store.commit('setSubmitting', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.user-search {
  padding: 20px 15px;
  background-color: $gray_lighten_color;
  border-radius: 15px;
  &__input {
    display: inline-block;
    width: calc(100% - 20px - 20px);
    font-size: 1.6rem;
    vertical-align: middle;
    &::placeholder {
      color: $gray_color;
    }
    &:focus {
      outline: none;
    }
  }
  &__btn {
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
    &.v-btn--icon.v-size--default {
      width: 20px;
      height: 20px;
    }
    &.theme--light.v-btn.v-btn--icon {
      color: $black_color;
    }
    &.theme--light.v-btn.v-btn--disabled .v-icon {
      color: $gray_color !important;
    }
    &.v-btn--icon.v-size--default .v-icon {
      width: 20px;
      height: 20px;
      font-size: 2rem;
    }
    &__icon {
      font-family: $material-outlined;
    }
  }
}
</style>
